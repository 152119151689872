<template>
  <div class="columns">
    <div class="column is-one-third">
      <card-component
        title="1. Select the dataset to analyze"
        class="tile is-child"
        :displayHelp="$options.name"
      >
        <validation-observer v-slot="{ handleSubmit }">
          <validation-provider
            v-slot="{ errors }"
            :rules= {required:true}
            name="dataset"
          >
            <dataset-select
              label="Select a dataset"
              :dataset.sync="dataset"
              v-model="dataset"
              :withVarname="false"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
              icon="table"
              pack="fa"
              expanded
            />
          </validation-provider>
          <b-field label="Direction">
            <b-select
              v-model="direction"
              placeholder="Direction"
              expanded
              icon="asterisk"
            >
              <option value="rows">Individuals (rows)</option>
              <option value="columns">Variables (columns)</option>
            </b-select>
          </b-field>
          <b-field label="Maximum % of acceptable missingness in individuals/variables">
            <b-slider
              v-model="threshold"
              :min=0
              :max=100
              :step=1
              :tooltip=true
              :indicator=true
              type="is-grey"
            >
            <b-slider-tick :value="0">0</b-slider-tick>
            <b-slider-tick :value="100">100</b-slider-tick>
            </b-slider>
          </b-field>
          <hr />
          <div class="has-text-centered">
            <b-button rounded
              class="is-primary button-shadow"
              @click.prevent="handleSubmit(run)"
            >
              <span class="icon is-small"><i class="fa fa-cogs"></i></span>
              <span> Run </span>
            </b-button>
          </div>
        </validation-observer>
      </card-component>
    </div>
    <div class="column">
      <card-component title="2. Plots and numerical summaries" />
      <card-no-result v-show="!resultsLoaded">
        <strong>How to set options?<br></strong>
        <ul>
          <li>“Direction” is used to choose to remove either entire individuals
          (rows) or entire variables (columns).</li>
          <li>The chosen percentage corresponds to the maximum acceptable
          missingness. For instance, if 30% is chosen, it means that all
          individuals or variables with more than 30% of missing values will
          be removed.</li>
        </ul>
        Check help
        <img class="img-help" :src="require('@/assets/icons/Bt-interrogation-inactif.svg')" width="35" height="35">
        for further advice.
      </card-no-result>
      <div v-if="resultsLoaded">
        <error :type='"notifications"' ref="notifications"/>
        <div v-if="hasResults">
          <card-component>
            <b-tabs v-model="currentResult">
              <b-tab-item label="Plots" icon="bar-chart" value="plots" />
              <b-tab-item label="Extract" icon="plus" value="extract" />
            </b-tabs>
          </card-component>
          <div v-if="currentResult==='plots'">
            <card-component
              :title="basicTable.title"
            >
              <basic-table :substrRowname="false" :data="basicTable"/>
            </card-component>
            <card-component>
              <png :data="png" />
            </card-component>
          </div>
          <card-component v-if="currentResult==='extract'">
            <validation-observer v-slot="{ handleSubmit }">
              <validation-provider
                v-slot="{ errors, valid }"
                :rules= {excluded:datasetsNames,does_not_contains:true,authorize_name:true,not_too_long:true}
                :customMessages = "{excluded: 'This dataset name has already been used. Choose another one.',
                does_not_contains: 'The dataset name should not contain special symbols (except underscore) nor start with numbers.',
                authorize_name: 'This dataset name is not allowed',
                not_too_long: 'This dataset name is too long'}"
                name="Dataset name"
              >
              <b-field
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
                label="Dataset name">
                <b-input
                  v-model="userName"
                  expanded
                  icon="tag"
                  placeholder="Dataset name"
                ></b-input>
                <p class="control">
                  <b-button rounded
                    class="is-primary button-shadow"
                    @click="handleSubmit(rMissingExtractRemove)"
                    >
                    <span class="icon is-small"><i class="fa fa-plus"></i></span>
                    <span> Extract and create a new dataset </span>
                  </b-button>
                </p>
              </b-field>
              </validation-provider>
            </validation-observer>
          </card-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ranalysis } from '@/mixins/ranalysis'
import apiService from '@/services/apiService'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useErrorStore } from '@/stores/error'

export default {
  name: 'RMissingRemove',
  props: {
    datasetP: {
      type: String,
      default: null
    }
  },
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'dataset-select': () => import('@/components/ui/DatasetSelect.vue'),
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'basic-table': () => import('@/components/rresults/BasicTable.vue'),
    'png': () => import('@/components/rresults/PNG.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  mixins: [ranalysis],
  data () {
    return {
      dataset: this.datasetP,
      direction: 'rows',
      threshold: 30,
      userName: null,
      objectName: null,
      currentResult: 'plots'
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const errorStore = useErrorStore()
    return { workspaceStore, errorStore }
  },
  computed: {
    params: function () {
      return {
        'func_name': 'r_missing_showremove',
        'datasetName': this.dataset,
        'direction': this.direction,
        'threshold': this.threshold,
        'out_graph': true
      }
    },
    datasetsNames: function () {
      return this.workspaceStore.datasets.map(dataset => dataset.user_name)
    },
    datasetUserName: function () {
      return this.workspaceStore.datasets.filter(obj => obj.object_name === this.datasetP)[0].user_name
    }
  },
  methods: {
    afterRun: function (data) {
      this.objectName = data.ObjectName
      this.currentResult = 'plots'
      this.setUserName()
    },
    rMissingExtractRemove: function () {
      this.workspaceStore.runRFunction({
        'func_name': 'r_missing_extractremove',
        'ObjectName': this.objectName,
        'userName': this.userName,
        'out_graph': true
      })
        .then(data => {
          if ('Messages' in data) {
            this.errorStore.setNotifications(data.Messages.data)
          } else {
            this.errorStore.setNotification({
              'type': 'message',
              'text': 'The dataset <strong>' + this.userName + '</strong> has been created.'
            })
            this.currentResult = 'plots'
          }
        })
    },
    setUserName: function () {
      this.userName = this.datasetUserName + '_' + 'MissRemoved_' + this.direction + '_' + this.threshold
    }
  },
  watch: {
    dataset: function () {
      this.currentResult = 'plots'
    },
    direction: function () {
      this.currentResult = 'plots'
    },
    threshold: function () {
      this.currentResult = 'plots'
    }
  },
  mounted () {
    this.setUserName()
    extend('does_not_contains', {
      getMessage: field => 'The ' + field + ' value is not truthy.',
      validate: value => value.match(/^[a-zA-Z0-9_]*$/) && !value.match(/^\d/)
    })
    extend('not_too_long', {
      getMessage: field => 'The ' + field + ' value is not truthy.',
      validate: value => value.length < 40
    })
    extend('authorize_name', (value) => {
      return apiService.runRFunction({
        'func_name': 'r_forbidden_name',
        'username': value
      }).then((res) => {
        return {
          valid: !res.forbidden
        }
      }, (err) => {
        return {
          valid: false,
          data: { message: 'This dataset name is forbidden.' + err }
        }
      })
    })
  }
}
</script>

<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
  .b-slider .b-slider-track {
    height: 0.5rem;
    background-color: #20BFF0;
}
</style>
